import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { refreshTokenRequest, refreshTokenPath } from './token';
import { STATUS_CODES } from '@app/constants/statusCodes';
import { RootState, Store } from '@app/store/store';
import { logout, setTokens } from '@app/store/slices/token';
import { ITokens } from '@app/interfaces/token';
import { configBaseUrl } from './config';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}

const axiosClient = axios.create();

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const defaultAxiosHeaders = headers;

// CONFIG ====================================================
axiosClient.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axiosClient.defaults.headers = { ...axiosClient.defaults.headers, ...headers };
axiosClient.defaults.timeout = 20000;
axiosClient.defaults.withCredentials = true;
// END CONFIG ================================================

export const interceptor = (store: typeof Store) => {
  axiosClient.interceptors.request.use(
    (conf: AxiosRequestConfig) => {
      const state = store.getState() as RootState;
      const { accessToken, refreshToken } = state.token;
      const isRefreshTokenReq = conf.url === refreshTokenPath;
      const targetToken = isRefreshTokenReq ? refreshToken : accessToken;

      const isConfig = conf.url != null && conf.url?.includes(configBaseUrl);

      if (targetToken.length) {
        if (conf.headers && !isConfig) {
          conf.headers['Authorization'] = `Bearer ${targetToken}`;
        }
      }

      return conf;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );
  axiosClient.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    async (error: AxiosError) => {
      const status = error?.response?.status;
      const dispatch = store.dispatch;
      const currentResponseHeader = error?.response?.config?.headers ?? {};
      const isTimeouted = error?.code === 'ECONNABORTED';
      const originalRequest = error.config as CustomAxiosRequestConfig;

      if (status === STATUS_CODES.INTERNAL_SERVER_ERROR || isTimeouted) {
        // dispatch(putErrorToQueue('errors.internalServerError')); TODO: Add error message queue
      }

      if (status === STATUS_CODES.UNAUTHORIZED && currentResponseHeader['Authorization']) {
        if (originalRequest.url === refreshTokenPath) {
          void dispatch(logout());
        } else {
          originalRequest._retry = true;
          const responseOfRefreshToken = await refreshTokenRequest();
          const newTokens = responseOfRefreshToken.data as ITokens;

          dispatch(
            setTokens({
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken,
            }),
          );

          //   await saveTokensInSecureStorage({ TODO: add tokens to local storage
          //     accessToken: newTokens.accessToken,
          //     refreshToken: newTokens.refreshToken,
          //   });

          axiosClient.defaults.headers.common['Authorization'] = `Bearer ${newTokens.accessToken}`;

          return axiosClient(originalRequest);
        }
      }

      return Promise.reject(error);
    },
  );
};

export const api = axiosClient;
